import React from'react'
import { SRLWrapper } from "simple-react-lightbox";
import { GatsbyImage } from'gatsby-plugin-image'
import { graphql } from'gatsby'
import {Box,Heading,Text,List,Button, ListItem,Center} from'@chakra-ui/react'
import { MyToast } from '../components/toast';
import {useToggle} from '../hooks/useToggle'
import {motion} from 'framer-motion'
import Link from '../components/gatsbylink'
import TonDokumente2 from '../components/tondokumente2';
const bildname=[
  {'nr':'01','name':'Ludwig Baum 1865 - 1942, Metzgermeister in der Bauerstr. 1a'},
  {'nr':'02','name':'Friederike Michel 1861 - 1942'},
  {'nr':'03','name':'Jenny oder Bertha Wolff'},
  {'nr':'04','name':'Jenny oder Bertha Wolff'},
  {'nr':'05','name':'Heinrich Paff, 1895 - 1941, Euthanasieopfer, vergast in Hadamar'},
  {'nr':'06','name':'Heinrich Paff, 1895 - 1941, Euthanasieopfer, Uhrmacher in Bacharach'},
  {'nr':'07','name':'Heinrich Paff, 1895 - 1941, Euthanasieopfer, Schul-Zeugnis'},
  {'nr':'08','name':'Louise Jeiter, 1891 - 1944,  Euthanasieopfer, vergast in Hadamar'},
  {'nr':'09','name':'Louise Jeiter,  Euthanasieopfer,'},
  {'nr':'10','name':'Louise Jeiter, Euthanasieopfer, - Dokument'},
  {'nr':'11','name':'Antonie Herzberg, Bacharach, 1862-1942, ermordet in Treblinka'},
  {'nr':'12','name':'Walter Herzberg, Motzstr., Berlin'},
  {'nr':'13','name':'Walter Herzberg, Neffe von Antonie Herzberg'},
  {'nr':'14','name':'Walter Herzberg co Dr.Ronit-Mercot'},
  {'nr':'15','name':'Walter Herzberg - Karikatur'},
  {'nr':'16','name':'Wilhelm - 1894 bis 1942 und Ella Keller - 1893-1942, Tod in Theresienstadt '},
  {'nr':'17','name':'Familie Eichberg'},
  {'nr':'18','name':'Ilse Sommer'},
  {'nr':'19','name':'Lotte Sommer'},
  {'nr':'20','name':'Max Halle'},
  {'nr':'21','name':'Blücherstr. 38-40, Häuser von Jenny u. Bertha Wolff'},
  {'nr':'22','name':'Bacharacher Nachrichten'},
  {'nr':'23','name':'Bacharacher Nachrichten'},
  {'nr':'24','name':'Übernahme Metzgerei'},
  {'nr':'25','name':'Geschäfte in Bacharach'},
  {'nr':'26','name':'Textilwaren Sommer'}
]

const options ={
  buttons: {
    showDownloadButton: false
  },
  thumbnails: {
    showThumbnails: true,
    thumbnailsAlignment:'center',
    thumbnailsContainerBackgroundColor:'transparent',
    thumbnailsContainerPadding:'0',
    thumbnailsGap:'0 1px',
    thumbnailsIconColor:'#ffffff',
    thumbnailsOpacity: 0.4,
    thumbnailsPosition:'bottom',
    thumbnailsSize: ['80px','80px']
  }
}

  function Bacharach({data}) {
    const [isVisible, setVisible] = useToggle();
 
    return (
     
<Box maxWidth="800" py="8">
 
      <div className="MyComponent">

        <SRLWrapper options={options}>
        <Heading pb="0" mb="0" variant="Titel">Jüdische Menschen aus Bacharach und  Opfer der Euthanasie</Heading> 
              <Text pb="1" fontSize="lg">Verschleppt, ermordert, verschollen, emigriert</Text> 
             
              <MyToast />
              

    <Box>
      <TonDokumente2 />
    </Box>
    <Box mt="8">
    <Link to="/herzberg">
        <Text>
        &rarr; Antonie Herzberg
        </Text>

      </Link>
      <Link to="/keller">
        <Text>
        &rarr; Wilhelm Keller (1894– ?) und Emma Keller (1893– ?)
        </Text>

      </Link>
      <Link to="/wolff">
        <Text>
        &rarr; Berta Wolff (1873–1942) und Jenny Wolff (1873–1942)
        </Text>
      </Link>
      <Link to="/eichberg">
        <Text>
        &rarr; Die Familien Eichberg, Halle, Keller und Sommer
        </Text>
      </Link>
      <Link to="/baum">
        <Text>
        &rarr; Ludwig Baum
        </Text>
      </Link>
    </Box>
      <List pt="8"  display='flex'  flexWrap="wrap">

           
          
{data.images.edges.map((e,index) => {

  return (
     
    
    <ListItem key={index}  minHeight="250" minWidth={['100%',"100%","49%","33%"]}     display='flex' width="33%" height="100%"> 
       <Box background="rgba(122,122,122,.2)" m="1" p="2" minHeight="300px" top="99%" border="1px solid" borderColor="whiteAlpha.900" position="relative"   cursor="pointer" key={index} >
             
             <GatsbyImage

image={e.node.childImageSharp.gatsbyImageData}

alt={bildname[index]!==undefined?bildname[index].name:""}

/>
<Box textAlign="center" display="inline-block" width="100%" bottom="0"  > 
<Text fontSize="xs"   display="inline-block" fontWeight="400" textAlign="center"   py="0" px="2">
  
  {bildname[index]?.name!==undefined?bildname[index].name:""}</Text></Box>
</Box>
  
   
 </ListItem>

  
  )

})}
</List>

        </SRLWrapper>
        <Button my="2" onClick={setVisible}>&rarr; Gedicht von Friedrich Paff, Bacharach</Button>
     { isVisible && <Box>
      <motion.div
      initial={{position:"absolute",left:0,right:0, zIndex:"400",bottom:-100,backgroundColor:'purple'}}
      animate={{bottom:260,left:0}}
      transition={{duration:3}}
      >
<Text p="4" textAlign="center" color="white"
     >
     
     Viehhändler. Metzger. <br/>Tuchhändler. Ausgeschaltete<br/> Konkurrenz. Das Tuch<br/> verkäuft sich nun besser. <br/>Vergessene Namen.<br/>
Die Gotteshäuser oben<br/>an der Synagoge.<br/>
Und andere größere,<br/> bessere. Frei, leer dann.<br/>Vergessene Namen. <br/>Eichberg. Wolf. Baum.<br/> Halles. Hallesse. Keller.<br/> Sommer. Herzberg.<br/>
Josef. Abraham. Moritz.<br/>
Das zurückgelassene Vermögen.<br/> Das im Sofa eingebaute, <br/>entdeckte. Die rechtzeitige <br/>Vertreibung.
Und die, die zurückblieben,<br/> die abtransportierten. <br/>Bacharach  
wo sind deine Juden
wo



     </Text>
     <Text p="3" mx="5" textAlign="center" color="white"> aus: <br/>Friedrich G. Paff,<br/> Die Hexe von Bacharach, Rossdorf 1983, S. 121, 123.</Text>
     <Center>
     <Text width="80%" variant="solid" color="white" p="3">Friedrich G. Paff, Bacharacher Dichter, dessen Onkel in Hadamar vergast worden ist, setzt sich in diesem Roman literarisch mit der Nazi-Zeit in Bacharach auseinander.</Text>
     </Center>
     <Text fontSize="3xl" cursor="pointer" p="3" color="white" onClick={setVisible}>X</Text>
      </motion.div>
     

    </Box>
    
    
}    
      </div>
      <Box>
        <Link href="https://www.gedenkstaette-hadamar.de/"><Text _hover={{color:'blue'}} pl="3"> &rarr; &nbsp; Euthanasie-Gedenkstätte Hadamar</Text></Link>
      </Box>
      </Box>
    );

  }
 


  export const query = graphql`

  {

    images: allFile(
      filter: { relativeDirectory: { eq: "bacharach" } }
      sort: { order: ASC, fields: base }
    ) {
      edges {

        node {

         base

          id
         
          relativePath

          childImageSharp {
             gatsbyImageData(
              layout: CONSTRAINED
              placeholder: BLURRED
              transformOptions: {
                cropFocus: ATTENTION
                fit: CONTAIN
                
              }
              aspectRatio: 1
              width: 900
            )
          }
          base
        }

      }

    }
    thumbnails: allFile(
        filter: { relativeDirectory: { eq: "bacharach/thumbnails" } }
        sort: { order: ASC, fields: name }
  
      ) {
  
        edges {
  
          node {
  
            name
  
            id
  
            relativePath
  
            childImageSharp {
  
              gatsbyImageData(
  
                placeholder: BLURRED
  
                layout: CONSTRAINED
  
                width: 120
  
                height: 120
               
              )
  
            }
  
          }
  
        }
  
      }
  
    

  }`


  export default Bacharach;




