import React from 'react'
import Player from './player'
import {Box,Text,Flex} from '@chakra-ui/react'


const TonDokumente2 =()=>{
return(
    <Flex direction={["column","column","row"]} mt="10">
   
    <Box px="2" py="2" borderLeft="4px gray solid">
    <Player url="/audio/ludwig-baum.mp3" />
        <Text>
            Ludwig Baum, Metzgermeister aus Bacharach
        </Text>
       
       
    </Box> 
    <Box px="2" pb="2" borderLeft="4px gray solid" >
    <Player url="/audio/erinnerungen1990.mp3" />
        <Text>
            Erinnerungen Ende der 90ziger Jahre
           
        </Text>
        
       
    </Box>

    <Box px="2" pb="2" borderLeft="4px gray solid">
    <Player url="/audio/koblenz-akte.mp3" />
        <Text>
            Aktenauszüge, Koblenz
        </Text>
       
       
    </Box>
    <Box px="2" pb="2" borderLeft="4px gray solid">
    <Player url="/audio/buergermeisterdrboeck.mp3" />
        <Text>
            Bericht von Bürgermeister Dr. Boeck
        </Text>
      </Box> 
       
   
 </Flex>

)
}
export default TonDokumente2